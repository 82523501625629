

import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import angryMan from "../images/angryman.jpg"
import ecoCars from "../images/ecocars.jpg"
import tracking from "../images/routes.jpg"
import carInterior from "../images/carInterior.jpg"
import price from "../images/surge.jpg"

const slides = [
    {
      image: ecoCars,
      title: "1. Eco-Friendly Vehicles for Sustainable Transportation",
      description: "To address the need for reliable and affordable transportation, we are introducing a fleet of 100% eco-friendly vehicles. This initiative aims to reduce emissions and offer cost-effective ride options, ensuring that urban populations have access to sustainable and affordable transportation solutions.",
      title2: "2. Integration of Electric Vehicles",
      description2: "To address air and noise pollution, we are integrating electric vehicles into our fleet. This move aims to reduce emissions and noise levels, contributing to a cleaner and quieter urban environment.",
    },
    {
      image: price,
      title: "3. Competitive Ride Fares with Transparent Pricing and guaranteed ride requests",
      description: "We are tackling high ride fares and surge pricing by implementing competitive and transparent pricing strategies. Our fare structures are designed to provide affordability making ride-hailing more accessible for everyone. \n \n\ To combat arbitrary ride request denials, we are establishing clear guidelines and accountability measures for drivers. This ensures that ride requests are handled fairly and promptly, improving the user experience and reliability of our service.",
      title2: "4. Enhanced Ride Availability and Reduced Wait Times",
      description2: "Our platform enhancement includes improved algorithms and increased driver-partner availability. This reduces wait times and ensures that users can quickly secure a ride, enhancing the overall convenience and efficiency of our service."
    },
    {
      image: carInterior,
      title: "5. Strict Cleanliness Standards and Professional Drivers",
      title2: "6. Innovative In-Car Amenities for Enhanced Comfort",
      description: "We are enhancing the user experience by implementing strict cleanliness standards and comprehensive driver training programs. This ensures that vehicles are hygienic and drivers maintain a high level of professionalism, providing a superior ride experience.",
      description2: "We are committed to offering innovative in-car amenities designed to enhance passenger comfort and satisfaction during every ride",
    },
    {
      image: tracking,
      title: "7. Advanced Ride Customization and Real-Time Tracking",
      title2: "8. Dedicated Hotline for Emergency Bookings",
      description: "Our platform now offers advanced ride customization options and real-time route tracking. Users can personalize their rides according to their preferences and monitor their journey, enhancing the overall experience and security.",
      description2: "We are introducing a dedicated customer support hotline for emergency ride bookings. This feature provides users with an additional layer of convenience and safety, allowing for quick ride arrangements in urgent situations."
    },
  ];
  
const SlideSolutions = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const props = useSpring({
    opacity: 1,
    transform: `translateX(-${currentIndex * 100}%)`,
    config: { duration: 500 }
  });

  return (
    <div className="slider-container relative">
      <div className=" slider-wrapper" style={{ overflow: 'hidden' }}>
        <animated.div className="slider-content flex" style={props}>
          {slides.map((slide, index) => (
            <div key={index} className="slide md:grid md:grid-cols-2 gap-12 p-8 w-full">
              <img src={slide.image} alt={slide.title} className="w-full h-auto shadow-lg rounded-xl" />
              <div className='h-auto flex flex-col items-center justify-center'>
                <h3 className='sub-heading-text text-4xl'>{slide.title}</h3>
                <p className='paragraph'>{slide.description}</p>
                <h3 className='sub-heading-text text-4xl'>{slide.title2}</h3>
                <p className='paragraph'>{slide.description2}</p>
              </div>
            </div>
          ))}
        </animated.div>
      </div>
      <button onClick={handlePrev} className="slide-button absolute -left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2">
        &lt;
      </button>
      <button onClick={handleNext} className="slide-button absolute -right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-4 py-2">
        &gt;
      </button>
    </div>
  );
};

export default SlideSolutions;

